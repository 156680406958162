import { ArrowBackIosNew } from "@mui/icons-material";
import { IconButton, Sheet, Typography } from "@mui/joy";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { KnowledgeCollectionEditor } from "../../../components/settings/rag/KnowledgeCollectionEditor.tsx";
import { useTranslateTrpcError } from "../../../lib/api/trpc/helpers/useTranslateTrpcError.ts";
import { trpc } from "../../../lib/api/trpc/trpc.ts";
import { Link, useParams } from "../../../router.ts";
import type { KnowledgeCollection } from "../../../../../backend/src/api/rag/knowledgeCollection/knowledgeCollectionTypes.ts";

export default function DataPoolSettings() {
  const { t } = useTranslation();
  const { organizationId, id } = useParams(
    "/:organizationId/settings/dataPool/:id"
  );
  const trpcUtils = trpc.useUtils();
  const translateError = useTranslateTrpcError();

  const { mutateAsync: updateKnowledgeCollection } =
    trpc.rag.knowledgeCollections.admin.update.useMutation();
  const { data: collectionToEdit } =
    trpc.rag.knowledgeCollections.admin.get.useQuery(
      {
        knowledgeCollectionId: id,
      },
      { enabled: id !== "new" }
    );

  const onUpdate = async (collection: KnowledgeCollection) => {
    try {
      await updateKnowledgeCollection({
        knowledgeCollectionId: id,
        patch: { ...collection },
      });
      toast.success(t("knowledgeBase.updated"));
      void trpcUtils.rag.knowledgeCollections.invalidate();
    } catch (e) {
      console.error(e);
      toast.error(
        t("knowledgeBase.updateFailed", { error: translateError(e) })
      );
    }
  };

  return (
    <Sheet className="h-screen">
      <div className="flex flex-col items-center px-10 pt-10">
        <div className="container flex flex-col gap-10">
          <div className="flex items-end gap-2">
            <IconButton
              component={Link}
              to={{
                pathname: "/:organizationId/settings",
                search: "tab=pools",
              }}
              params={{
                organizationId: organizationId,
              }}
            >
              <ArrowBackIosNew />
            </IconButton>
            <Typography level="h2">
              {!collectionToEdit
                ? t("knowledgeBase.create")
                : t("knowledgeBase.edit", { name: collectionToEdit?.name })}
            </Typography>
          </div>
          {collectionToEdit && (
            <KnowledgeCollectionEditor
              key={collectionToEdit.id}
              onSubmit={onUpdate}
              collection={collectionToEdit}
            />
          )}
        </div>
      </div>
    </Sheet>
  );
}
