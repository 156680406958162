import { useEffect } from "react";
import { useNavigate } from "../../router";
import { trpc } from "./trpc/trpc";

export function useOrganization() {
  return trpc.organization.getOrganization.useQuery().data;
}

export function usePartOfCurrentOrganization() {
  const navigate = useNavigate();
  const { error: orgError } = trpc.organization.getOrganization.useQuery();

  useEffect(() => {
    if (
      orgError &&
      ["NOT_FOUND", "FORBIDDEN"].includes(orgError.data?.code ?? "")
    ) {
      navigate("/", { replace: true });
    }
  }, [orgError]);
}
