import { Link } from "@mui/icons-material";
import { Button, Card, CardContent, Typography, Tooltip } from "@mui/joy";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import type { DataVaultConnectionStatus } from "../../../../../backend/src/api/rag/dataVault/dataVaultTypes.ts";
import { trpc } from "../../../lib/api/trpc/trpc.ts";
import { SettingsPage } from "../SettingsPage.tsx";
import CreateDataVaultModal from "./dataVault/CreateDataVaultModal.tsx";

interface StatusDetails {
  status?: DataVaultConnectionStatus["status"];
  error?: string | null;
}

function ConnectionStatusIndicator({ details }: { details: StatusDetails }) {
  const { status, error } = details;
  const { t } = useTranslation();

  let color = "gray";
  let title = t("rag.dataVault.status.unknown");
  let shouldPulse = false;

  if (status === "healthy") {
    color = "green";
    title = t("rag.dataVault.status.healthy");
    shouldPulse = true;
  } else if (status === "unhealthy") {
    color = "orange";
    title = t("rag.dataVault.status.unhealthy");
    shouldPulse = true;
  } else if (status === "disconnected") {
    color = "red";
    title = t("rag.dataVault.status.disconnected");
  }

  const tooltipContent = (
    <div className="text-sm">
      <div>
        <strong>{t("rag.dataVault.status.title")}:</strong> {title}
      </div>
      {error && (
        <div>
          <strong>{t("rag.dataVault.status.error")}:</strong> {error}
        </div>
      )}
    </div>
  );

  return (
    <Tooltip title={tooltipContent} variant="outlined" arrow placement="right">
      <div className="absolute right-3 top-3">
        {shouldPulse && (
          <div
            className="absolute h-3 w-3 animate-ping rounded-full"
            style={{ backgroundColor: color, opacity: 0.75 }}
          />
        )}
        <div
          className="relative h-3 w-3 rounded-full"
          style={{ backgroundColor: color }}
        />
      </div>
    </Tooltip>
  );
}

export default function DataVaults() {
  const { t } = useTranslation();
  const [createModalOpen, setCreateModalOpen] = useState(false);

  const { data: dataVaults } = trpc.rag.dataVaults.getAll.useQuery();
  const { data: connectionStatuses } =
    trpc.rag.dataVaults.getConnectionStatus.useQuery(undefined, {
      refetchInterval: 5000, // Refetch every 5 seconds
    });

  const statusDetailsMap = new Map(
    connectionStatuses?.map((status) => [
      status.id,
      {
        status: status.status,
        error: status.error,
        lastChecked: status.checkedAt,
      },
    ])
  );

  return (
    <SettingsPage
      title={t("rag.dataVault.title")}
      subtitle={t("rag.dataVault.subtitle")}
      actions={
        <Button
          sx={{ whitespace: "nowrap" }}
          onClick={() => setCreateModalOpen(true)}
        >
          {t("rag.dataVault.create")}
        </Button>
      }
    >
      <CreateDataVaultModal
        onClose={() => setCreateModalOpen(false)}
        open={createModalOpen}
      />
      <div className="flex flex-wrap gap-3">
        {dataVaults?.map((vault) => {
          const details = statusDetailsMap.get(vault.id);
          return (
            <Card
              variant="outlined"
              key={vault.id}
              sx={{ minWidth: "275px", position: "relative" }}
            >
              <ConnectionStatusIndicator
                details={{
                  status: details?.status,
                  error: details?.error,
                }}
              />
              <CardContent>
                <Typography level="h2" fontSize="md">
                  {vault.name}
                </Typography>
                <Typography color="neutral">{vault.id}</Typography>
                {vault.customUrl && (
                  <div className="flex items-center gap-2">
                    <Link />
                    <Typography sx={{ mt: 0.5 }}>{vault.customUrl}</Typography>
                  </div>
                )}
              </CardContent>
            </Card>
          );
        })}
      </div>
    </SettingsPage>
  );
}
