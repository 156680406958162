import { ArrowForward } from "@mui/icons-material";
import { Card, Typography } from "@mui/joy";
import { useState } from "react";
import type {
  Chat,
  ModelOverride,
} from "../../../../backend/src/api/chat/chatTypes";
import { ChatInput } from "../../components/chat/ChatInput";
import { ChatSettingsMenu } from "../../components/chat/ChatSettingsMenu";
import type { RagModeInput } from "../../components/chat/ChatSourceContainer";
import { useGuide } from "../../components/onboarding/useGuide";
import { trpc } from "../../lib/api/trpc/trpc";
import { useQueuedMessagesStore } from "../../lib/context/queuedMessagesStore";
import { useTranslation } from "../../lib/i18n";
import { useRelativeTime } from "../../lib/util";
import { useNavigate, useParams } from "../../router";

export function ChatIndexScreen() {
  const navigate = useNavigate();
  const params = useParams("/:organizationId");
  const queueMessage = useQueuedMessagesStore((s) => s.addQueuedMessage);
  const { setRun, setStep, step, completed } = useGuide();

  const utils = trpc.useUtils();
  const { mutateAsync: createChat } = trpc.chat.create.useMutation();
  const [selectedModel, setSelectedModel] = useState<ModelOverride | null>(
    null
  );

  const postMessage = async ({
    content,
    attachmentIds,
    ragMode,
  }: {
    content: string;
    attachmentIds: string[];
    ragMode: RagModeInput;
  }) => {
    const chat = await createChat({
      modelOverride: selectedModel ?? undefined,
      ragMode: ragMode.mode,
      customSourceId: ragMode.customSourceId,
    });

    await utils.chat.invalidate();

    navigate(`/:organizationId/chats/:chatId`, {
      params: {
        ...params,
        chatId: chat.id,
      },
    });

    queueMessage({
      content,
      chatId: chat.id,
      attachmentIds,
    });

    if (!completed) {
      setRun(false);
      setStep(step + 1);
    }
  };

  const handleRagSuggestion = ({ id, query }: RagSuggestion) => {
    void postMessage({
      content: query,
      attachmentIds: [],
      ragMode: { mode: "CUSTOM", customSourceId: id },
    });
  };

  return (
    <div className="flex flex-1 flex-col items-stretch">
      <div className="flex flex-1 flex-col items-center">
        <div className="flex flex-col items-center gap-12">
          <LastChatsTiles />
          <RagSuggestions onClick={handleRagSuggestion} />
        </div>
      </div>
      <div className="flex flex-col items-stretch justify-center gap-4 bg-gradient-to-t from-white from-50% to-transparent p-10 py-8">
        <ChatInput
          postMessage={postMessage}
          model={selectedModel}
          setModelOverride={setSelectedModel}
          startDecorator={
            <ChatSettingsMenu
              selectedModel={selectedModel}
              setSelectedModel={setSelectedModel}
            />
          }
        />
      </div>
    </div>
  );
}

function LastChatsTiles() {
  const { t } = useTranslation();

  const chats = trpc.chat.getAll.useQuery().data;
  const lastChats = chats?.slice(0, 3) ?? [];

  if (!chats || chats.length === 0) return;
  return (
    <div className="flex flex-col items-center ">
      <Typography level="h2" color="neutral">
        {t("continue")}
      </Typography>
      <div className="grid grid-cols-3 gap-3 p-4">
        {lastChats.map((chat) => (
          <ChatTile key={chat.id} chat={chat} />
        ))}
      </div>
    </div>
  );
}

function ChatTile({ chat }: { chat: Chat }) {
  const navigate = useNavigate();
  const params = useParams("/:organizationId");
  const time = useRelativeTime(new Date(chat.updatedAt));

  return (
    <Card
      className="flex w-64 cursor-pointer flex-col justify-between transition-all hover:shadow-lg"
      onClick={() => {
        navigate(`/:organizationId/chats/:chatId`, {
          params: {
            ...params,
            chatId: chat.id,
          },
        });
      }}
    >
      <Typography
        level="title-md"
        sx={{
          maxHeight: "8rem",
          overflow: "hidden",
          wordBreak: "break-word",
        }}
      >
        {chat.name}
      </Typography>
      <div className="flex flex-row items-center justify-between">
        <Typography level="body-sm">{time}</Typography>
        <ArrowForward />
      </div>
    </Card>
  );
}

interface RagSuggestion {
  id: string;
  query: string;
}

interface RagSuggestionsProps {
  onClick: ({ id, query }: RagSuggestion) => void;
}
function RagSuggestions({ onClick }: RagSuggestionsProps) {
  const { t } = useTranslation();

  const { data: collections } =
    trpc.rag.knowledgeCollections.getSuggestions.useQuery();

  if (!collections || collections.length === 0) return;
  return (
    <div className="flex flex-col items-center">
      <Typography level="h2" color="neutral">
        {t("knowledgeBase.companyKnowledgeQuestion")}
      </Typography>
      <div className="grid grid-cols-3 gap-3 rounded-lg p-4">
        {collections.map(({ id, name, query }, index) => (
          <Card
            onClick={() =>
              onClick({
                id,
                query,
              })
            }
            key={index}
            className="flex w-64 cursor-pointer flex-col justify-between transition-all hover:shadow-lg"
          >
            <Typography
              level="title-md"
              sx={{
                maxHeight: "8rem",
                overflow: "hidden",
                wordBreak: "break-word",
              }}
            >
              {query}
            </Typography>
            <div className="flex flex-row items-center justify-between space-x-2">
              <Typography level="body-sm">{name}</Typography>
              <ArrowForward />
            </div>
          </Card>
        ))}
      </div>
    </div>
  );
}
