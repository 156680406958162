import z from 'zod';
export const imageStyles = [
  'none',
  'advertising',
  'digital_illustration',
  'interior',
  'logo',
  'memphis',
  'minimalistic',
  'productMockup',
  'productPresentation',
  'realistic_image',
  'socialMedia',
  'stock',
  'ui',
  'vector_illustration',
  'wallpaper',
] as const;

export const ImageStyle = z.enum(imageStyles);
export type ImageStyle = z.infer<typeof ImageStyle>;

export const promptSuffixes: Record<ImageStyle, string> = {
  none: '',
  advertising: ', high quality marketing image',
  digital_illustration: ',  styled as a beautiful professional illustration',
  interior: ', beautiful interior design, spacious, comfortable',
  logo: 'realistic minimal professional logo design: "{{prompt}}"',
  memphis:
    ', corporate memphis, flat art, minimal, friendly, simple, no details',
  minimalistic: ', minimalist style, simple, very few details',
  productMockup: 'Product mockup of a {{prompt}}, to be used for R&D purposes',
  productPresentation:
    'High quality product photography to be used in a corporate keynote presentation of {{prompt}}',
  realistic_image: ', hyperrealistic, photo, unedited',
  socialMedia: ', high quality stock photo for social media',
  stock: ', high quality stock photo, realistic',
  ui: ', minimalist UI mockup, 2D',
  vector_illustration: ', vector graphic, clear lines, single color',
  wallpaper: 'high quality wallpaper of {{prompt}}',
};

export const ImageResponse = z.object({
  urls: z.string().array(),
  price: z.number(),
  errorMessage: z.string().nullable().optional(),
});
export type ImageResponse = z.infer<typeof ImageResponse>;

export const ImageProvider = z.enum(['black-forest-labs', 'recraft-ai']);
export type ImageProvider = z.infer<typeof ImageProvider>;

export const FluxModels = [
  'flux-schnell',
  'flux-pro',
  'flux-1.1-pro-ultra',
] as const;

export const RecraftModels = ['recraft-v3', 'recraft-v3-svg'] as const;

export const IMAGE_MODELS = [...FluxModels, ...RecraftModels] as const;

export const ImageModel = z.enum(IMAGE_MODELS);
export type ImageModel = z.infer<typeof ImageModel>;

// The provider name is used in Image generation requests $PROVIDER/$MODEL for the replicate API
// Check the provider names on the replicate model documentation
export const IMAGE_PROVIDERS_MODELS: Record<ImageProvider, ImageModel[]> = {
  'black-forest-labs': [...FluxModels],
  'recraft-ai': [...RecraftModels],
};

export const ImageAspectRatio = z.enum(['1:1', '16:9', '9:16']);
export type ImageAspectRatio = z.infer<typeof ImageAspectRatio>;

export const ImageRequest = z.object({
  provider: ImageProvider,
  model: ImageModel,
  numberOfImages: z.number(),
  prompt: z.string(),
  style: ImageStyle,
  aspectRatio: ImageAspectRatio,
});
export type ImageRequest = z.infer<typeof ImageRequest>;

export const DefaultImageRequest: ImageRequest = {
  provider: 'black-forest-labs',
  model: 'flux-pro',
  numberOfImages: 1,
  prompt: '',
  style: 'realistic_image',
  aspectRatio: '1:1',
};

export const ImageGeneration = z.object({
  id: z.string(),
  urls: z.string().array(),
  model: z.string(), // supported models can change over time so ImageModel is not usable here
  prompt: z.string(),
  style: z.string(),
  aspectRatio: z.string(),
  createdAt: z.string(),
  userId: z.string(),
});
export const ImagesHistory = z.array(ImageGeneration);

export type ImageGeneration = z.infer<typeof ImageGeneration>;
export type ImagesHistory = z.infer<typeof ImagesHistory>;
