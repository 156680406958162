import { Badge, Code, Login, Logout, Person } from "@mui/icons-material";
import {
  Avatar,
  Dropdown,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  Typography,
} from "@mui/joy";
import { skipToken } from "@tanstack/react-query";
import type { ComponentProps } from "react";
import { useState } from "react";
import { useLogout } from "../../lib/api/auth";
import type { UserXP } from "../../lib/api/gamification";
import { calcUserXP, useXP } from "../../lib/api/gamification";
import { trpc } from "../../lib/api/trpc/trpc";
import { useUser } from "../../lib/api/user";
import { useTranslation } from "../../lib/i18n";
import { Link, useModals, useNavigate, useParams } from "../../router";
import { AvatarWithProgressAndLevel } from "../gamification/AvatarWithXP";
import { XPBar } from "../gamification/XPBar";
import { LanguageSelector } from "../settings/languageSelector";
import { DelayedLoader } from "../util/DelayadLoader";

export function UserMenu({
  languageSelector,
  isAcademy,
}: {
  languageSelector?: boolean;
  isAcademy?: boolean;
}) {
  const logout = useLogout();
  const orgs = trpc.organization.getAllOrganizations.useQuery().data;
  const hasMultiple = (orgs?.length ?? 0) > 1;
  const navigate = useNavigate();
  const modals = useModals();
  const params = useParams("/:organizationId");
  const me = useUser("me");
  const xp = useXP();

  const { data: apiKeysEnabled } = trpc.apiKeys.enabled.useQuery(
    params.organizationId ? undefined : skipToken,
    {
      trpc: {
        context: {
          silentError: true,
        },
      },
    }
  );

  const { t } = useTranslation();

  const menuItemStyle = "flex flex-row items-center gap-2";

  const UserXPData = calcUserXP(xp?.xp ?? 0, 0);

  const [open, setOpen] = useState(false);

  const handleOpenChange = (
    e: React.SyntheticEvent | null,
    isOpen: boolean
  ) => {
    const id = (e?.target as HTMLElement).id;
    if (id !== "languageSelector") {
      setOpen(isOpen);
    }
  };

  return (
    <Dropdown open={open} onOpenChange={handleOpenChange}>
      <MenuButton
        slots={{
          root: "div",
        }}
      >
        <UserAvatar userId="me" showXP={isAcademy} userXPData={UserXPData} />
      </MenuButton>
      <Menu placement="bottom-end">
        {isAcademy && <XPBar userXPData={UserXPData} />}
        {languageSelector && (
          <MenuItem>
            <LanguageSelector
              sx={{ width: "100%" }}
              filter={isAcademy ? ["de", "en"] : undefined}
            />
          </MenuItem>
        )}
        {me && (
          <MenuItem
            onClick={() => {
              modals.open("/[organizationId]/tools/profileSettings", {
                params,
              });
            }}
          >
            <div className={menuItemStyle}>
              <Person />
              <span>{t("settings.profile")}</span>
            </div>
          </MenuItem>
        )}
        {apiKeysEnabled && (
          <MenuItem
            onClick={() => {
              modals.open("/[organizationId]/tools/apiKeys", {
                params,
              });
            }}
          >
            <div className={menuItemStyle}>
              <Code />
              <span>{t("settings.apiKeys")}</span>
            </div>
          </MenuItem>
        )}
        {hasMultiple && (
          <MenuItem
            onClick={() => {
              navigate("/");
            }}
          >
            <div className={menuItemStyle}>
              <Badge />
              <span>{t("switchOrganization")}</span>
            </div>
          </MenuItem>
        )}
        {me && (
          <MenuItem color="danger" onClick={logout}>
            <div className={menuItemStyle}>
              <Logout />
              <span>{t("logout")}</span>
            </div>
          </MenuItem>
        )}
      </Menu>
    </Dropdown>
  );
}

export function UserAvatar({
  userId,
  showXP = false,
  userXPData,
  ...rest
}: {
  userId?: string | null;
  showXP?: boolean;
  userXPData?: UserXP;
} & ComponentProps<typeof Avatar>) {
  const user = useUser(userId ?? "me");

  if (!userId) return <Avatar />;

  if (!user) return <Avatar />;

  const textContent = () => {
    if (user.firstName && user.lastName) {
      return user.firstName[0] + user.lastName[0];
    } else if (user.firstName) {
      return user.firstName.substring(0, 2);
    } else if (user.lastName) {
      return user.lastName.substring(0, 2);
    } else if (user.primaryEmail) {
      return user.primaryEmail.substring(0, 2);
    } else {
      return null;
    }
  };

  if (showXP) {
    return (
      <AvatarWithProgressAndLevel
        imageUrl={user.imageUrl ?? undefined}
        progress={userXPData?.normalizedProgress ?? 0}
        level={userXPData?.currentLevel ?? 1}
        textContent={textContent}
        {...rest}
      />
    );
  } else {
    return (
      <Avatar
        variant="solid"
        color="primary"
        src={user.imageUrl ?? undefined}
        {...rest}
      >
        {textContent()?.toUpperCase()}
      </Avatar>
    );
  }
}

export function UserDisplay({ userId }: { userId: string }) {
  const user = useUser(userId);
  return (
    <div className="flex flex-row items-center gap-2">
      <UserAvatar size="sm" userId={userId} />
      <Typography>
        {user?.firstName} {user?.lastName}
      </Typography>
    </div>
  );
}

/**
 * Display the user menu if the user is logged in, otherwise display login button
 */
export function OptionalUserMenu() {
  const user = trpc.user.me.useQuery(undefined, {
    trpc: {
      context: {
        silentError: true,
      },
    },
  });

  if (user.isLoading) return <DelayedLoader />;

  if (user.data) return <UserMenu />;

  if (user.error) {
    return (
      <Link to="/auth">
        <IconButton>
          <Login />
        </IconButton>
      </Link>
    );
  }
}
