import {
  Delete,
  Upload as UploadIcon,
  Lock,
  InsertDriveFile,
} from "@mui/icons-material";
import {
  Alert,
  Button,
  Card,
  IconButton,
  Skeleton,
  Table,
  Tooltip,
  Typography,
} from "@mui/joy";
import { useEffect, useRef, useState } from "react";
import { trpc } from "../../../../lib/api/trpc/trpc.ts";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { readableFileSize } from "../../../../lib/util.ts";
import { useDateFormatter } from "../../../../lib/hooks/useDateFormatter.tsx";

export default function FileSourceEditor({
  knowledgeCollectionId,
}: {
  knowledgeCollectionId: string;
}) {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const format = useDateFormatter();
  const { t } = useTranslation();

  const { mutateAsync: presignUrls } =
    trpc.rag.knowledgeCollections.admin.presignUrl.useMutation();

  const {
    mutateAsync: deleteFile,
    isPending: deleteFileLoading,
    variables: deleteFileVars,
  } = trpc.rag.knowledgeCollections.admin.deleteFile.useMutation();

  const { data: files } =
    trpc.rag.knowledgeCollections.admin.getFilesData.useQuery({
      knowledgeCollectionId,
    });

  const utils = trpc.useUtils();

  const removeFile = async (name: string) => {
    try {
      await deleteFile({
        knowledgeCollectionId,
        fileName: name,
      });

      await utils.rag.knowledgeCollections.admin.getFilesData.invalidate();
      toast.success(t("modals.dataConnectors.fileUpload.fileDeleted"));
    } catch (e) {
      toast.error("knowledgeBase.fileNotDeleted");
    }
  };

  const onDrop = async (droppedFiles: File[]) => {
    setIsLoading(true);
    const currentToast = toast.loading(t("common.upload.started"));
    const totalFiles = droppedFiles.length;
    let failedUploads = 0;

    try {
      const urls = await presignUrls({
        knowledgeCollectionId,
        files: droppedFiles.map(({ name }) => name),
      });

      for (let i = 0; i < totalFiles; i++) {
        toast.update(currentToast, {
          render: t("common.upload.inProgress", {
            currentFile: i + 1,
            totalFiles,
          }),
        });

        const [file, url] = [droppedFiles[i], urls[i]];

        try {
          await fetch(url, {
            method: "PUT",
            body: file,
          });
        } catch (err) {
          failedUploads++;
          console.error(err);
          toast.error(t("common.upload.failed", { fileName: file.name }));
        }
      }

      await utils.rag.knowledgeCollections.admin.getFilesData.invalidate();
      toast.dismiss(currentToast);
    } finally {
      if (failedUploads > 0) {
        toast.warning(
          t("common.upload.partialSuccess", {
            successCount: totalFiles - failedUploads,
            totalCount: totalFiles,
          })
        );
      } else {
        toast.success(t("common.upload.success"));
      }

      setIsLoading(false);
    }
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    open: openFilePicker,
  } = useDropzone({
    onDrop,
    multiple: true,
    disabled: isLoading,
    noClick: true,
    useFsAccessApi: false,
  });

  return (
    <div>
      <Alert
        variant="soft"
        startDecorator={<Lock sx={{ height: 16, width: 16 }} />}
      >
        <Typography level="body-sm">
          {t("modals.dataConnectors.fileUpload.encryption")}
        </Typography>
      </Alert>
      <div className="mb-2 flex flex-row items-center justify-between"></div>

      <div className="flex flex-row gap-2"></div>

      <div className="max-h-96 overflow-y-auto">
        <Table stickyHeader>
          <thead>
            <tr>
              <th colSpan={2}>{t("common.file")}</th>
              <th>{t("common.lastModified")}</th>
              <th>{t("common.size")}</th>
            </tr>
          </thead>
          {false &&
            Array.from({ length: 3 }).map((_, i) => (
              <SkeletonTableRow key={i} />
            ))}
          {files?.map((file, index) => (
            <tr key={index}>
              <td colSpan={2}>
                <FileNameWithTooltip file={file} />
              </td>
              <td>
                <Typography>
                  {file.lastModified ? format(file.lastModified, "Pp") : "-"}
                </Typography>
              </td>
              <td>
                <div className="flex items-center justify-between">
                  <Typography>{readableFileSize(file.size)}</Typography>
                  <IconButton
                    onClick={() => removeFile(file.name)}
                    loading={
                      deleteFileLoading && deleteFileVars.fileName === file.name
                    }
                    color="danger"
                    size="sm"
                  >
                    <Delete />
                  </IconButton>
                </div>
              </td>
            </tr>
          ))}
          {files?.length === 0 && (
            <tr>
              <td colSpan={2}>
                <Typography>
                  {t("modals.dataConnectors.fileUpload.noFiles")}
                </Typography>
              </td>
            </tr>
          )}
        </Table>
      </div>
      <Card
        {...getRootProps()}
        sx={{
          cursor: "pointer",
          borderWidth: 1.5,
          borderColor: isDragActive ? "transparent" : undefined,
        }}
        variant={isDragActive ? "soft" : "outlined"}
        color={isDragActive ? "primary" : "neutral"}
        className="mt-1 cursor-pointer transition-all"
        onClick={openFilePicker}
      >
        <input {...getInputProps()} hidden id="dropzoneInput" />
        <div className="flex flex-col items-center justify-center space-y-2">
          <Button startDecorator={<UploadIcon />} loading={false}>
            {t("common.upload.files")}
          </Button>
          <Typography color="neutral" level="body-sm">
            {t("modals.dataConnectors.fileUpload.uploadHint")}
          </Typography>
        </div>
      </Card>
    </div>
  );
}

function SkeletonTableRow() {
  return (
    <tr>
      {Array.from({ length: 2 }).map((_, i) => (
        <td key={i}>
          <Skeleton variant="text" />
        </td>
      ))}
    </tr>
  );
}
const FileNameWithTooltip = ({ file }: { file: { name: string } }) => {
  const [isOverflow, setIsOverflow] = useState(false);
  const textRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    const el = textRef.current;
    if (el) {
      setIsOverflow(el.scrollWidth > el.clientWidth);
    }
  }, [file.name]);

  return (
    <div className="flex flex-row items-center gap-2">
      <InsertDriveFile />
      <Tooltip title={isOverflow ? file.name : ""}>
        <Typography ref={textRef} className="truncate">
          {file.name.split("/").pop()}
        </Typography>
      </Tooltip>
    </div>
  );
};
