import {
  KeyboardArrowRight,
  Upload,
  Language,
  Google,
  Storage,
  Security,
  CloudSync,
  CloudOutlined,
  FolderShared,
  ArrowBack,
} from "@mui/icons-material";
import {
  Chip,
  List,
  ListItem,
  ListItemButton,
  ListItemContent,
  ListItemDecorator,
  Modal,
  ModalClose,
  ModalDialog,
  ModalOverflow,
  Stack,
  Typography,
  Button,
} from "@mui/joy";
import { useTranslation } from "react-i18next";
import type { KnowledgeDataSourceType } from "../../../../../backend/src/api/rag/knowledgeCollection/knowledgeCollectionTypes";
import { trpc } from "../../../lib/api/trpc/trpc";
import { useState } from "react";

interface SourceOption {
  type: KnowledgeDataSourceType;
  icon: React.ReactNode;
  text: string;
  description: string;
  disabled?: boolean;
  supportedHostingTypes: ("ON_PREMISE" | "CLOUD")[]; // TODO use enum imported from backend
}

export function AddSourceModal({
  open,
  onClose,
  onChoice,
}: {
  open: boolean;
  onClose: VoidFunction;
  onChoice: (type: KnowledgeDataSourceType, vaultId: string) => void;
}) {
  const { t } = useTranslation();
  const [selectedVault, setSelectedVault] = useState<string | null>(null);
  const { data: dataVaults } = trpc.rag.dataVaults.getAll.useQuery();

  const options: SourceOption[] = [
    {
      type: "MANUAL_UPLOAD",
      icon: <Upload />,
      text: "Manual Upload",
      description: t("knowledgeBase.dataSources.descriptions.manualUpload"),
      supportedHostingTypes: [], // Currently not implementd
    },
    {
      type: "WEB_SCRAPER",
      icon: <Language />,
      text: "Web Scraper",
      description: t("knowledgeBase.dataSources.descriptions.webScraper"),
      supportedHostingTypes: [], // Currently not implemented
    },
    {
      type: "SHAREPOINT",
      icon: <FolderShared />,
      text: "SharePoint",
      description: t("knowledgeBase.dataSources.descriptions.sharepoint"),
      supportedHostingTypes: ["CLOUD", "ON_PREMISE"],
    },
    {
      type: "ONEDRIVE",
      icon: <CloudOutlined />,
      text: "OneDrive",
      description: t("knowledgeBase.dataSources.descriptions.onedrive"),
      supportedHostingTypes: ["CLOUD", "ON_PREMISE"],
    },
    {
      type: "GOOGLE_DRIVE",
      icon: <Google />,
      text: "Google Drive",
      description: t("knowledgeBase.dataSources.descriptions.googleDrive"),
      supportedHostingTypes: ["CLOUD", "ON_PREMISE"],
    },
    {
      type: "WEBDAV",
      icon: <CloudSync />,
      text: "WebDAV",
      description: t("knowledgeBase.dataSources.descriptions.webdav"),
      supportedHostingTypes: ["ON_PREMISE", "CLOUD"],
    },
    {
      type: "FTP",
      icon: <Storage />,
      text: "FTP",
      description: t("knowledgeBase.dataSources.descriptions.ftp"),
      supportedHostingTypes: ["ON_PREMISE", "CLOUD"],
    },
    {
      type: "SFTP",
      icon: <Security />,
      text: "SFTP",
      description: t("knowledgeBase.dataSources.descriptions.sftp"),
      supportedHostingTypes: ["ON_PREMISE", "CLOUD"],
    },
  ];

  const selectedVaultData = dataVaults?.find(
    (vault) => vault.id === selectedVault
  );
  const filteredOptions = options.filter((option) =>
    option.supportedHostingTypes.includes(selectedVaultData?.hostingType || "")
  );

  const handleBack = () => {
    setSelectedVault(null);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <ModalOverflow>
        <ModalDialog maxWidth="sm" minWidth="sm">
          <ModalClose />
          <Stack spacing={2}>
            <div>
              <Typography level="h4">
                {t("knowledge_base.data_pool.add_source_modal.title")}
              </Typography>
              {!selectedVault ? (
                <Typography level="body-sm">
                  {t("knowledge_base.data_pool.add_source_modal.select_vault")}
                </Typography>
              ) : (
                <Typography level="body-sm">
                  {t("knowledge_base.data_pool.add_source_modal.select_method")}
                </Typography>
              )}
            </div>

            {!selectedVault ? (
              <List sx={{ gap: 1.5 }}>
                {dataVaults?.map((vault) => (
                  <ListItem key={vault.id}>
                    <ListItemButton onClick={() => setSelectedVault(vault.id)}>
                      <ListItemContent>
                        <Typography>{vault.name}</Typography>
                        <Typography level="body-sm" color="neutral">
                          {vault.hostingType == "ON_PREMISE"
                            ? "On-Premise"
                            : "Cloud"}
                        </Typography>
                      </ListItemContent>
                      <KeyboardArrowRight />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            ) : (
              <>
                <Button
                  variant="plain"
                  onClick={handleBack}
                  sx={{ alignSelf: "flex-start" }}
                  startDecorator={<ArrowBack />}
                >
                  {t("knowledge_base.data_pool.add_source_modal.back_to_vault")}
                </Button>
                <List sx={{ gap: 1.5 }}>
                  {filteredOptions.map((option, index) => (
                    <DataSourceOption
                      onClick={() => onChoice(option.type, selectedVault)}
                      key={index}
                      option={option}
                    />
                  ))}
                </List>
              </>
            )}
          </Stack>
        </ModalDialog>
      </ModalOverflow>
    </Modal>
  );
}

interface DataSourceOptionProps {
  onClick: VoidFunction;
  option: SourceOption;
}

function DataSourceOption({ option, onClick }: DataSourceOptionProps) {
  const { text, description, icon, disabled } = option;
  const { t } = useTranslation();

  return (
    <ListItem>
      <ListItemButton onClick={onClick} disabled={disabled}>
        <ListItemDecorator>{icon}</ListItemDecorator>
        <ListItemContent>
          <Stack direction="row" mb={0.5} gap={1}>
            <Typography
              textColor="common.black"
              sx={{ opacity: disabled ? 0.5 : 1 }}
              level="body-sm"
            >
              {text}
            </Typography>
            {disabled && (
              <Chip size="sm" color="primary">
                {t("common.available_soon")}
              </Chip>
            )}
          </Stack>

          <Typography
            textColor="neutral.600"
            sx={{ opacity: disabled ? 0.7 : 1 }}
            level="body-xs"
          >
            {description}
          </Typography>
        </ListItemContent>
        <KeyboardArrowRight />
      </ListItemButton>
    </ListItem>
  );
}
