import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { Option, Select } from "@mui/joy";
import { AutoAwesome, KeyboardArrowDown } from "@mui/icons-material";

interface LanguagePickerProps {
  selectedLanguage: string;
  languages: string[];
  onSelect: (language: string) => void;
  testId?: string;
}

export function LanguagePicker({
  selectedLanguage,
  languages,
  onSelect,
  testId,
}: LanguagePickerProps) {
  const { t } = useTranslation();

  const sortedLanguages = useMemo(() => {
    return [...languages].sort((a, b) => {
      if (a === "detect" || b === "detect") return 1;
      return t("languages." + a).localeCompare(t("languages." + b));
    });
  }, [t, languages]);

  return (
    <Select
      value={t("languages." + selectedLanguage)}
      indicator={<KeyboardArrowDown />}
      data-testid={testId}
      renderValue={(o) => (
        <>
          {o?.value}
          {o?.value === t("languages.detect") && (
            <AutoAwesome color="primary" sx={{ fontSize: "20px", ml: 0.5 }} />
          )}
        </>
      )}
    >
      {sortedLanguages.map((lang) => (
        <Option
          key={lang}
          value={t("languages." + lang)}
          onClick={() => onSelect(lang)}
        >
          {t("languages." + lang)}
          {lang === "detect" && <AutoAwesome color="primary" />}
        </Option>
      ))}
    </Select>
  );
}
