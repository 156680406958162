export { ErrorDisplay as Catch } from "../components/util/ErrorDisplay";
import { ChevronRight } from "@mui/icons-material";
import {
  Button,
  Card,
  FormControl,
  FormLabel,
  Input,
  Typography,
} from "@mui/joy";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { BrandedLogo } from "../components/branding/BrandedLogo";
import { LanguageSelector } from "../components/settings/languageSelector";
import { DelayedLoader } from "../components/util/DelayadLoader";
import { usePartOfCurrentOrganization } from "../lib/api/organization";
import { useMe, useMutateMe } from "../lib/api/user";
import { useOrganizationApi } from "../lib/hooks/useApi";
import { useTranslation } from "../lib/i18n";
import { useNavigate, useParams } from "../router";

type PageProps = { continue: () => void };

export default function OrganizationOnboarding() {
  const { t } = useTranslation();
  const [step, setStep] = useState(1);
  const api = useOrganizationApi();
  const navigate = useNavigate();
  const params = useParams("/:organizationId/onboarding");
  const me = useMe();
  const mutateMe = useMutateMe();

  usePartOfCurrentOrganization();

  useEffect(() => {
    if (me && me.onboarded) {
      navigate("/:organizationId", { params, replace: true });
    }
  }, [me?.onboarded]);

  const continueFn = () => {
    if (step === 1) {
      api
        .patch("/users/me", { onboarded: true })
        .catch((err) => {
          console.error(err);
          toast.error(t("saveFailed"));
        })
        .finally(async () => {
          await mutateMe();
          navigate("/:organizationId", { params, replace: true });
        });
    }
    setStep(step + 1);
  };

  return (
    <div className="relative flex h-screen w-screen flex-col items-center justify-center">
      <div className="absolute right-0 top-0 p-4">
        <LanguageSelector />
      </div>
      {(() => {
        switch (step) {
          case 0:
            return <WelcomePage continue={continueFn} />;
          case 1:
            return <ProfileInformationCheck continue={continueFn} />;
          default:
            return null;
        }
      })()}
    </div>
  );
}

function WelcomePage(props: PageProps) {
  const { t } = useTranslation();
  const [onScreen, setOnScreen] = useState(false);
  const [button, setButton] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setOnScreen(true);
    }, 100);
    setTimeout(() => {
      setButton(true);
    }, 1000);
  }, []);

  return (
    <div className="flex flex-col items-center gap-16">
      <h1
        className="text-6xl font-thin"
        style={{
          opacity: onScreen ? 1 : 0,
          translate: onScreen ? "none" : "0 10rem",
          transition: "1000ms ease-in-out",
        }}
      >
        {t("welcomeTo")} meinGPT.
      </h1>
      <Button
        onClick={props.continue}
        size="lg"
        style={{
          opacity: button ? 1 : 0,
          transition: "800ms ease-in-out",
        }}
      >
        {t("next")}
      </Button>
    </div>
  );
}

function ProfileInformationCheck(props: PageProps) {
  const { t } = useTranslation();

  const me = useMe();
  const api = useOrganizationApi();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const disabled = !firstName || !lastName;

  if (!me) return <DelayedLoader />;

  return (
    <div className="flex flex-col items-center gap-10">
      <div
        style={{
          width: "clamp(240px, 24vw, 600px)",
          maxHeight: "clamp(150px, 12vw, 300px)",
        }}
      >
        <BrandedLogo />
      </div>
      <Card>
        <Typography level="h3" sx={{ mr: 20, mb: 2 }}>
          {t("accountRegistration")}
        </Typography>
        <form
          className="flex flex-col gap-4"
          onSubmit={(e) => {
            e.preventDefault();
            const formData = new FormData(e.target as HTMLFormElement);
            api
              .patch("/users/me", Object.fromEntries(formData))
              .catch((err) => {
                console.error(err);
                toast.error(t("saveFailed"));
              });
            props.continue();
          }}
        >
          <FormControl className="inline flex-1">
            <FormLabel>{t("firstName")}</FormLabel>
            <Input
              defaultValue={me.firstName ?? undefined}
              name="firstName"
              placeholder={t("firstName")}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </FormControl>
          <FormControl className="inline flex-1">
            <FormLabel>{t("lastName")}</FormLabel>
            <Input
              defaultValue={me.lastName ?? undefined}
              name="lastName"
              placeholder={t("lastName")}
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </FormControl>
          <div className="mt-5 flex flex-col items-end">
            <Button size="lg" type="submit" disabled={disabled}>
              <div className="flex flex-row items-center gap-2">
                {t("done")}
                <ChevronRight />
              </div>
            </Button>
          </div>
        </form>
      </Card>
    </div>
  );
}
