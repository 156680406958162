import { Add, Bookmark, MoreVert, Warning } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Alert,
  Avatar,
  Card,
  Dropdown,
  IconButton,
  ListItemDecorator,
  Menu,
  MenuButton,
  MenuItem,
  Stack,
  Typography,
} from "@mui/joy";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import type {
  DetailedKnowledgeCollection,
  KnowledgeDataSourceType,
} from "../../../../../backend/src/api/rag/knowledgeCollection/knowledgeCollectionTypes.ts";
import { useCurrentOrganizationId } from "../../../lib/api/trpc/helpers/useCurrentOrganizationId.ts";
import { useTranslateTrpcError } from "../../../lib/api/trpc/helpers/useTranslateTrpcError.ts";
import { trpc } from "../../../lib/api/trpc/trpc.ts";
import { useNavigate } from "../../../router.ts";
import { DelayedLoader } from "../../util/DelayadLoader.tsx";
import { SettingsPage } from "../SettingsPage.tsx";
import { AddSourceModal } from "./DataSourceModal.tsx";

export default function DataPools() {
  const [showModal, setShowModal] = useState<boolean>(false);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const trpcUtils = trpc.useUtils();

  const translateError = useTranslateTrpcError();
  const organizationId = useCurrentOrganizationId();

  const { data: knowledgeCollections, isLoading } =
    trpc.rag.knowledgeCollections.admin.getAll.useQuery();

  const { mutateAsync: deleteKnowledgeCollection } =
    trpc.rag.knowledgeCollections.admin.delete.useMutation();

  const { mutateAsync: createKnowledgeCollection } =
    trpc.rag.knowledgeCollections.admin.create.useMutation();

  const onDelete = async ({ id, name }: { id: string; name: string }) => {
    if (!confirm(t("knowledgeBase.confirmDelete", { name }))) {
      return;
    }

    try {
      await deleteKnowledgeCollection({
        knowledgeCollectionId: id,
      });
      toast.success(t("knowledgeBase.deleted"));
      void trpcUtils.rag.knowledgeCollections.invalidate();
    } catch (e) {
      console.error(e);
      toast.error(
        t("knowledgeBase.deleteFailed", { error: translateError(e) })
      );
    }
  };

  const openSource = (id: string) => {
    navigate("/:organizationId/settings/dataPool/:id", {
      params: {
        organizationId,
        id,
      },
    });
  };

  const createSource = async (
    type: KnowledgeDataSourceType,
    vaultId: string
  ) => {
    const { id } = await createKnowledgeCollection({
      name: t("knowledgeBase.newDataSource"),
      sourceType: type,
      vaultId,
    });
    openSource(id);
    setShowModal(false);
  };

  return (
    <SettingsPage
      title={t("knowledgeBase.dataSources.title")}
      subtitle={t("knowledgeBase.dataSources.subtitle")}
    >
      {isLoading && <DelayedLoader />}

      {!isLoading && (
        <div className="flex flex-wrap gap-4">
          {knowledgeCollections?.map((collection, index) => (
            <DataSourceCard
              onClick={() => openSource(collection.id)}
              onDelete={() =>
                onDelete({ id: collection.id, name: collection.name })
              }
              dataSource={collection}
              key={index}
            />
          ))}
          <Card
            onClick={() => setShowModal(true)}
            sx={{
              width: "25%",
              minWidth: 350,
              aspectRatio: 1 / 0.75,
              cursor: "pointer",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Avatar size="md" className="row-span-2">
              <Add sx={{ height: 18, width: 18 }} />
            </Avatar>
            <Typography textColor="common.black" level="body-sm">
              {t("knowledgeBase.dataSources.addSource")}
            </Typography>
          </Card>
        </div>
      )}

      <AddSourceModal
        open={showModal}
        onClose={() => setShowModal(false)}
        onChoice={createSource}
      />
    </SettingsPage>
  );
}

interface DataSourceCardProps {
  dataSource: DetailedKnowledgeCollection;
  onClick: () => void;
  onDelete: () => void;
}

function DataSourceCard({
  onClick,
  onDelete,
  dataSource,
}: DataSourceCardProps) {
  const { t } = useTranslation();
  const { name, description, departments } = dataSource;

  return (
    <Card
      onClick={onClick}
      sx={{
        width: 1 / 4,
        minWidth: 350,
        aspectRatio: 1 / 0.75,
        cursor: "pointer",
      }}
      className="transition-all hover:scale-[102%]"
    >
      <Stack direction="row" gap={2}>
        <Avatar size="md" className="row-span-2">
          <Bookmark sx={{ height: 18, width: 18 }} />
        </Avatar>
        <Stack>
          <Typography textColor="common.black" level="title-sm">
            {name}
          </Typography>
        </Stack>
        <Dropdown>
          <MenuButton
            onClick={(e) => e.stopPropagation()}
            slots={{ root: IconButton }}
            sx={{ ml: "auto" }}
            slotProps={{ root: { variant: "plain", color: "neutral" } }}
          >
            <MoreVert />
          </MenuButton>
          <Menu placement="bottom-end">
            <MenuItem
              onClick={(e) => {
                e.stopPropagation();
                onDelete();
              }}
            >
              <ListItemDecorator>
                <DeleteIcon />
              </ListItemDecorator>
              {t("Delete")}
            </MenuItem>
          </Menu>
        </Dropdown>
      </Stack>
      <Typography
        textColor={description ? "common.black" : "neutral.500"}
        level="body-sm"
      >
        {description ?? t("knowledgeBase.missingDescription")}
      </Typography>
      {!description ? (
        <Alert
          color="warning"
          startDecorator={<Warning />}
          sx={{ marginTop: "auto" }}
        >
          <div>
            <div>{t("knowledgeBase.missingDataTitle")}</div>
            <Typography level="body-xs" color="warning">
              {t("knowledgeBase.missingDataSubtitle")}
            </Typography>
          </div>
        </Alert>
      ) : (
        <Stack sx={{ mt: "auto" }} spacing={0.5}>
          <Typography textColor="common.black" level="title-sm">
            {t("knowledgeBase.availableFor")}
          </Typography>
          <div className="flex flex-wrap gap-1">
            {departments.map((department) => (
              <div
                key={department.id}
                className="flex h-6 items-center rounded-full bg-neutral-200 px-2"
              >
                <Typography textColor="common.black" level="body-xs">
                  {department.name}
                </Typography>
              </div>
            ))}
            {departments.length === 0 && (
              <div className="flex h-6 items-center rounded-full bg-neutral-200 px-2">
                <Typography textColor="common.neutral" level="body-xs">
                  {t("knowledgeBase.noneChosen")}
                </Typography>
              </div>
            )}
          </div>
        </Stack>
      )}
    </Card>
  );
}
