import {
  Autocomplete,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/joy";
import { Form, Formik } from "formik";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";
import type { KnowledgeCollection } from "../../../../../backend/src/api/rag/knowledgeCollection/knowledgeCollectionTypes.ts";
import { trpc } from "../../../lib/api/trpc/trpc.ts";
import { useMe } from "../../../lib/api/user.ts";
import { FormikSwitch } from "../../forms/FormikSwitch.tsx";
import { FormikTextarea } from "../../forms/FormikTextArea.tsx";
import { FormikTextField } from "../../forms/FormikTextField.tsx";
import FileSourceEditor from "./sources/FileSourceEditor.tsx";
import WebsiteSourceEditor from "./sources/WebsiteSourceEditor.tsx";

export function KnowledgeCollectionEditor({
  onSubmit,
  collection,
}: {
  onSubmit: (values: KnowledgeCollection) => void;
  collection: KnowledgeCollection;
}) {
  const { t } = useTranslation();
  const me = useMe();

  const { mutateAsync: triggerSync } =
    trpc.rag.knowledgeCollections.admin.triggerSync.useMutation();

  const { data: departments } = trpc.organization.department.all.useQuery();

  const sourceData = useMemo(() => {
    const { id } = collection;
    switch (collection.sourceType) {
      case "WEB_SCRAPER":
        return {
          title: t("knowledgeBase.dataSources.websiteSources"),
          render: <WebsiteSourceEditor />,
        };

      case "MANUAL_UPLOAD":
        return {
          title: t("knowledgeBase.dataSources.fileUpload"),
          render: <FileSourceEditor knowledgeCollectionId={id} />,
        };

      default:
        return null;
    }
  }, [collection, t]);

  return (
    <div className="pb-12">
      <Formik
        initialValues={collection}
        validationSchema={toFormikValidationSchema(
          z.object({
            name: z.string().min(2),
            description: z.string().min(2),
          })
        )}
        onSubmit={onSubmit}
        validateOnMount
      >
        {({ values, isSubmitting, setFieldValue, isValid }) => (
          <Form className="flex flex-col gap-12">
            <section>
              <Typography level="title-md" mb={3}>
                {t("knowledgeBase.baseSettings")}
              </Typography>
              <div className="flex flex-col gap-4">
                <FormikTextField name="name" label={t("name")} required />
                <FormikTextarea
                  name="description"
                  label={t("description")}
                  minRows={3}
                  helperText={t("knowledgeBase.descriptionHelper")}
                  required
                />
              </div>
            </section>

            {sourceData && (
              <section>
                <Typography level="title-md" mb={3}>
                  {sourceData.title}
                </Typography>
                {sourceData.render}
              </section>
            )}

            <section>
              <Typography level="title-md" mb={3}>
                {t("knowledgeBase.permissions")}
              </Typography>
              <FormControl>
                <FormLabel>{t("knowledgeBase.assignedDepartments")}</FormLabel>
                <Autocomplete
                  multiple
                  options={departments ?? []}
                  getOptionLabel={(option) =>
                    departments?.find((d) => d.id === option.id)?.name ?? ""
                  }
                  getOptionKey={(option) => option.id}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  value={values.departments}
                  onChange={(e, value) => setFieldValue("departments", value)}
                />
                <FormHelperText>
                  {t("knowledgeBase.assignedDepartmentsHelper")}
                </FormHelperText>
              </FormControl>
            </section>

            <section>
              <Accordion>
                <AccordionSummary>
                  <Typography level="title-md">
                    {t("knowledgeBase.searchConfig.title")}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ pt: 3 }}>
                  <div className="flex flex-col gap-4">
                    <FormikSwitch
                      name="ragSettings.search.useHybridSearch"
                      label={t("knowledgeBase.searchConfig.doHybridSearch")}
                      helperText={t(
                        "knowledgeBase.searchConfig.hybridSearchHelper"
                      )}
                    />
                    <FormikTextField
                      name="ragSettings.search.searchLimit"
                      label={t("knowledgeBase.searchConfig.searchLimit")}
                      type="number"
                      slotProps={{
                        input: {
                          min: 1,
                          max: 100,
                          step: 1,
                        },
                      }}
                    />
                    <div className="my-1" />
                    <FormikSwitch
                      name="ragSettings.reranking.enabled"
                      label={t("knowledgeBase.searchConfig.useReranking")}
                      helperText={t(
                        "knowledgeBase.searchConfig.rerankingHelper"
                      )}
                    />
                    <FormikTextField
                      disabled={!values.ragSettings?.reranking?.enabled}
                      name="ragSettings.reranking.topN"
                      label={t("knowledgeBase.searchConfig.rerankLimit")}
                      type="number"
                      slotProps={{
                        input: {
                          min: 1,
                          max: 100,
                          step: 1,
                        },
                      }}
                    />
                  </div>
                </AccordionDetails>
              </Accordion>
            </section>

            <div className="flex justify-end gap-2">
              {me?.isSuperUser && (
                <Button
                  variant="outlined"
                  onClick={() =>
                    triggerSync({ knowledgeCollectionId: collection.id })
                  }
                >
                  {t("Trigger Resync")}
                </Button>
              )}
              <Button
                disabled={!isValid}
                type="submit"
                variant="solid"
                loading={isSubmitting}
              >
                {t("save")}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
