import { Box } from "@mui/joy";
import * as Sentry from "@sentry/react";
import { useState } from "react";
import { toast } from "react-toastify";
import type {
  DeepLSourceLanguageWithDetect,
  DeepLTargetLanguage,
} from "../../../../../backend/src/api/tools/translateContent/deeplTranslator/deeplTranslatorLanguages";
import { trpc } from "../../../lib/api/trpc/trpc";
import { useTranslation } from "../../../lib/i18n";
import {
  base64ToBlob,
  downloadBlob,
  fileToBase64,
  splitByExtension,
  targetToSourceLanguage,
} from "../../../lib/util";
import { type SelectedLanguages } from "../../../pages/[organizationId]/tools/translateContent";
import TranslateTable from "../TranslateTable";
import { DocumentTranslationError } from "./translationStates/DocumentTranslationError";
import { FileChosen } from "./translationStates/FileChosen";
import { Ready } from "./translationStates/Ready";

const MB = 1_048_576;

// Sizes in bytes
const MAX_SIZE_LIMITS = {
  doc: 30 * MB,
  docx: 30 * MB,
  pptx: 30 * MB,
  ppt: 30 * MB,
  xlsx: 30 * MB,
  xls: 30 * MB,
  pdf: 30 * MB,
  txt: MB,
  html: 5 * MB,
  htm: 5 * MB,
  xlf: 10 * MB,
  xliff: 10 * MB,
  srt: MB,
};

export type TranslationState = "READY" | "FILE_CHOSEN" | "ERROR";

export default function DocumentPanel({
  selectedLanguages,
  setSelectedLanguages,
  glossaryId,
}: {
  selectedLanguages: SelectedLanguages;
  setSelectedLanguages: (languages: SelectedLanguages) => void;
  glossaryId?: string;
}) {
  const { t } = useTranslation();
  const [file, setFile] = useState<File | undefined>();

  const translateMutation =
    trpc.tools.translateContent.documentTranslator.translate.useMutation({
      trpc: {
        context: {
          silentError: true,
        },
      },
    });

  const uploadDocument = translateMutation.mutateAsync;

  function checkSizeLimit(newFile: File) {
    const { name, size } = newFile;
    const [, extension] = splitByExtension(name);
    const max = MAX_SIZE_LIMITS[extension.toLowerCase()];
    if (max == undefined) {
      Sentry.captureException(
        new Error(
          `File with unsupported extension selected for DeepL document translation`
        ),
        {
          extra: {
            fileName: name,
            fileSize: size,
          },
        }
      );
      return false;
    }
    const valid = size <= max;
    if (!valid) {
      toast.error(t("errors.fileTooLarge", { size: max / (1024 * 1024) })); // convert bytes to MB
    }
    return valid;
  }

  function updateFile(newFile?: File) {
    if (newFile != null && !checkSizeLimit(newFile)) {
      return;
    }
    setFile(newFile);
  }

  const { sourceLanguage, targetLanguage } = selectedLanguages;

  const handleTranslation = async () => {
    if (!file) {
      toast.error(t("common.upload.failed", { fileName: "" }));
      return;
    }
    try {
      const { name, type } = file;
      const [filename, extension] = splitByExtension(name);
      const content = (await fileToBase64(file)).split(",")[1]; // split to remove metadata

      const {
        document: { data },
      } = await uploadDocument({
        content,
        extension,
        source_lang: sourceLanguage as DeepLSourceLanguageWithDetect,
        target_lang: targetLanguage as DeepLTargetLanguage,
        glossaryId,
      });

      const blob = base64ToBlob(data, type);
      const cleanLanguage = targetToSourceLanguage(targetLanguage);
      downloadBlob(blob, `${filename}_${cleanLanguage}`);
      updateFile(undefined);
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
    }
  };

  let translationState: TranslationState = "READY";
  if (translateMutation.isError) {
    translationState = "ERROR";
  } else if (file) {
    translationState = "FILE_CHOSEN";
  }

  return (
    <TranslateTable
      selectedLanguages={selectedLanguages}
      isLoading={translateMutation.isPending}
      setSelectedLanguages={setSelectedLanguages}
    >
      <Box
        display="flex"
        sx={{
          alignItems: "center",
          justifyContent: "center",
          height: "50vh",
          maxHeight: "600px",
        }}
      >
        <div className="flex h-full w-full flex-col items-center justify-center gap-10">
          {
            {
              READY: <Ready setFile={updateFile} />,
              FILE_CHOSEN: (
                <FileChosen
                  file={file}
                  setFile={updateFile}
                  handleTranslation={handleTranslation}
                  loading={translateMutation.isPending}
                />
              ),
              ERROR: (
                <DocumentTranslationError
                  handleTranslation={handleTranslation}
                  message={translateMutation.error?.message}
                  resetMutation={translateMutation.reset}
                  setFile={updateFile}
                />
              ),
            }[translationState]
          }
        </div>
      </Box>
    </TranslateTable>
  );
}
