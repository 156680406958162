import { format } from "date-fns";
import { toast } from "react-toastify";

export function downloadImage(url: string, errorMessage: string) {
  // Fetching the image from the URL is required to download svg images
  fetch(url)
    .then((response) => {
      if (!response.ok) {
        toast.error(errorMessage);
        throw new Error("The image could not be downloaded");
      }
      return response.blob(); // Parse response as a Blob
    })
    .then((blob) => {
      const extension = url.split("?")[0].split(".").pop();

      // Create a URL for the Blob
      const blobUrl = URL.createObjectURL(blob);

      // Create a temporary anchor element
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = `${format(new Date(), "yyyy-MM-dd_HH-mm-ss")}.${extension}`; // Add proper file extension

      // Trigger the download
      document.body.appendChild(link);
      link.click();

      // Clean up
      document.body.removeChild(link);
      URL.revokeObjectURL(blobUrl);
    })
    .catch(() => {
      toast.error("The image could not be downloaded");
    });
}
