import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalClose,
  ModalDialog,
  Stack,
  Typography,
  Alert,
} from "@mui/joy";
import { Field, Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import z from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { trpc } from "../../../../lib/api/trpc/trpc";

const validationSchema = z.object({
  name: z.string().min(1),
  url: z.string().url("Invalid URL format").optional(),
});

type FormValues = z.infer<typeof validationSchema>;

export default function CreateDataVaultModal({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  const { t } = useTranslation();

  const initialValues: FormValues = {
    name: "",
    url: "",
  };

  const { mutateAsync, data, reset } = trpc.rag.dataVaults.create.useMutation();

  const handleSubmit = async (values: FormValues, { resetForm }) => {
    try {
      await mutateAsync({
        name: values.name,
        url: values.url != "" ? values.url : undefined,
      });
      resetForm();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog minWidth="sm">
        <ModalClose />
        {data ? (
          <Stack gap={2}>
            <Typography level="h4">{t("rag.dataVault.created")}</Typography>
            <Typography>{t("rag.dataVault.configure")}</Typography>
            <FormControl>
              <FormLabel>ID</FormLabel>
              <Input
                value={data.id}
                readOnly
                endDecorator={
                  <Button
                    onClick={() => navigator.clipboard.writeText(data.id)}
                  >
                    {t("copy")}
                  </Button>
                }
              />
            </FormControl>
            <FormControl>
              <FormLabel>Secret Key</FormLabel>
              <Input
                value={data.secretKey}
                readOnly
                fullWidth
                endDecorator={
                  <Button
                    onClick={() =>
                      navigator.clipboard.writeText(data.secretKey)
                    }
                  >
                    {t("copy")}
                  </Button>
                }
              />
            </FormControl>
            <Alert color="danger">{t("rag.dataVault.secretKeyWarning")}</Alert>
            <Button
              onClick={() => {
                reset();
                onClose();
              }}
            >
              {t("close")}
            </Button>
          </Stack>
        ) : (
          <Formik
            initialValues={initialValues}
            validationSchema={toFormikValidationSchema(validationSchema)}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, isSubmitting }) => (
              <Form>
                <Stack gap={2} sx={{ margin: "auto" }}>
                  <Typography level="h4" component="h1">
                    New DataVault
                  </Typography>

                  <FormControl error={touched.name && !!errors.name}>
                    <FormLabel>Name</FormLabel>
                    <Field as={Input} name="name" />
                    {touched.name && errors.name && (
                      <FormHelperText>{errors.name}</FormHelperText>
                    )}
                  </FormControl>

                  <FormControl error={touched.url && !!errors.url}>
                    <FormLabel>URL (Optional)</FormLabel>
                    <Field as={Input} name="url" />
                    {touched.url && errors.url && (
                      <FormHelperText>{errors.url}</FormHelperText>
                    )}
                  </FormControl>

                  <Button type="submit" loading={isSubmitting}>
                    Create Connection
                  </Button>
                </Stack>
              </Form>
            )}
          </Formik>
        )}
      </ModalDialog>
    </Modal>
  );
}
