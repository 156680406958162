import { BarChart, RestartAlt, ThumbUp } from "@mui/icons-material";
import ClearIcon from "@mui/icons-material/Clear";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import SendSharpIcon from "@mui/icons-material/SendSharp";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  IconButton,
  Sheet,
  Textarea,
  Typography,
} from "@mui/joy";
import { useRef, useState, useEffect } from "react";
import ConfettiExplosion from "react-confetti-explosion";
import { toast } from "react-toastify";
import type { SupportBotSession } from "../../../../../backend/src/api/tools/supportAssistant/supportAssistantTypes.ts";
import { UserAvatar } from "../../../components/auth/UserMenu.tsx";
import { MarkdownRenderer } from "../../../components/chat/MarkdownRenderer.tsx";
import { useSmoothTypingText } from "../../../components/chat/useSmoothTypingText.ts";
import { trpc } from "../../../lib/api/trpc/trpc.ts";
import { useUser } from "../../../lib/api/user.ts";
import { cn } from "../../../lib/cn.ts";
import { useTranslation } from "../../../lib/i18n";
import { useModals } from "../../../router.ts";

enum ProblemSolutionState {
  Solved,
  Unknown,
}

export default function SupportAssistantPage() {
  const modals = useModals();
  const { t } = useTranslation();
  const user = useUser("me");

  const [creatingTicket, setCreatingTicket] = useState(false);
  const { mutateAsync: createSession } =
    trpc.tools.supportAssistant.createSession.useMutation();
  const { mutateAsync: updateSession } =
    trpc.tools.supportAssistant.updateSession.useMutation();

  const [botSession, setBotSession] = useState<SupportBotSession | null>(null);

  const [generating, setGenerating] = useState<boolean>(false);
  const [problemDescription, setProblemDescription] = useState<string>("");
  const [edited, setEdited] = useState<boolean>(false);
  const chatContainerRef = useRef<HTMLDivElement>(null);

  const [messages, setMessages] = useState<
    {
      byAi: boolean;
      content: string;
    }[]
  >([]);

  const smoothedResponse = useSmoothTypingText(
    messages[messages.length - 1]?.content ?? ""
  );

  const { mutateAsync: summarizeHelpRequest } =
    trpc.tools.supportAssistant.summarizeHelpRequest.useMutation({
      trpc: { context: { silentError: true } },
    });

  const [problemSolutionState, setProblemSolutionState] =
    useState<ProblemSolutionState>(ProblemSolutionState.Unknown);

  const { mutateAsync: findSolution } =
    trpc.tools.supportAssistant.findSolution.useMutation();

  const cancelCurrent = useRef<() => void>(() => {});

  function reset() {
    if (cancelCurrent.current) {
      cancelCurrent.current();
    }
    setBotSession(null);
    setProblemSolutionState(ProblemSolutionState.Unknown);
    setMessages([]);
    setProblemDescription("");
  }

  function scrollToBottom() {
    chatContainerRef.current!.scrollTo({
      top: chatContainerRef.current!.scrollHeight,
      behavior: "smooth",
    });
  }

  const generateSolution = async (problem: string) => {
    if (!problem || generating || !edited) return;
    setEdited(false);
    setMessages((prev) => [
      ...prev,
      {
        byAi: false,
        content: problem,
      },
    ]);
    setProblemDescription("");
    let session: SupportBotSession | null;
    if (botSession === null) {
      session = await createSession();
    } else {
      session = await updateSession({
        id: botSession.id,
      });
    }
    setBotSession(session);

    cancelCurrent.current();

    let cancelled = false;

    cancelCurrent.current = () => {
      if (cancelled) return;
      cancelled = true;
      setGenerating(false);
    };

    setGenerating(true);

    try {
      const stream = await findSolution({
        sessionId: session!.id,
        request: problem,
      });

      setMessages((prev) => [
        ...prev,
        {
          byAi: true,
          content: "",
        },
      ]);
      for await (const chunk of stream) {
        if (cancelled) return;
        setMessages((prev) => [
          ...prev.slice(0, prev.length - 1),
          {
            byAi: true,
            content: chunk,
          },
        ]);
      }
      setProblemSolutionState(ProblemSolutionState.Unknown);
      scrollToBottom();
      if (cancelled) return;
    } finally {
      setGenerating(false);
    }
  };

  const hasStartedChat = generating || messages.length > 0;

  async function createSupportTicket() {
    setCreatingTicket(true);
    try {
      if (botSession != null) {
        await updateSession({
          id: botSession.id,
          issueSolved: false,
          ticketCreated: true,
        });
      }
      const ticketContent = await summarizeHelpRequest({
        messages,
      });
      window.open(
        `mailto:it_helpdesk@lauda.de?subject=${ticketContent.subject}&body=${encodeURIComponent(ticketContent.body)}`,
        "_blank"
      );
    } catch (e) {
      toast.error(t("supportAssistant.ticketCreateFailed"));
    } finally {
      setCreatingTicket(false);
    }
  }

  async function onProblemSolved() {
    if (botSession != null) {
      setBotSession(
        await updateSession({
          id: botSession.id,
          issueSolved: true,
        })
      );
    }
    setProblemSolutionState(ProblemSolutionState.Solved);
  }

  useEffect(() => {
    scrollToBottom();
  }, [messages.length]);

  return (
    <Sheet variant="soft" className="flex h-screen flex-col overflow-hidden">
      <div className="flex-none px-8 pt-8">
        <div className="mb-6 flex items-center justify-between">
          <Typography level="h2">
            <SupportAgentIcon sx={{ mr: 2, fontSize: "1.8em" }} />
            {t("supportAssistant.title")}
          </Typography>
          <div className="flex gap-2">
            {hasStartedChat && (
              <Button onClick={reset} startDecorator={<RestartAlt />}>
                {t("supportAssistant.restart")}
              </Button>
            )}
            {(user?.isOrganizationAdmin || user?.isSuperUser) && (
              <Button
                onClick={() =>
                  modals.open(
                    "/[organizationId]/tools/supportAssistantAnalytics"
                  )
                }
                startDecorator={<BarChart />}
              >
                {t("supportAssistant.analytics.title")}
              </Button>
            )}
          </div>
        </div>

        {!hasStartedChat && (
          <Typography level="h4" mb={2}>
            {t("supportAssistant.inputLabel")}
          </Typography>
        )}
      </div>

      <div
        className={`flex-grow overflow-y-auto px-8 transition-all duration-300 ${messages.length === 0 ? "max-h-0" : "max-h-full"}`}
        ref={chatContainerRef}
      >
        <div className="flex flex-col gap-4">
          {messages.map((message, index) => (
            <div key={index} className="flex flex-col gap-2">
              <div
                className={cn(
                  "flex gap-2",
                  !message.byAi && "flex-row-reverse"
                )}
              >
                <Typography color={message.byAi ? "primary" : "neutral"}>
                  {message.byAi ? (
                    <Avatar variant="outlined">
                      <SupportAgentIcon />
                    </Avatar>
                  ) : (
                    <UserAvatar userId={user?.id} />
                  )}
                </Typography>
                <Card sx={{ width: "calc(90% - 40px)" }}>
                  <CardContent>
                    <MarkdownRenderer
                      content={
                        index === messages.length - 1
                          ? smoothedResponse
                          : message.content
                      }
                    />
                  </CardContent>
                </Card>
              </div>
              <div className="flex justify-end gap-2"></div>
            </div>
          ))}
        </div>
      </div>

      <div className="flex-none p-8 pt-4">
        {
          {
            [ProblemSolutionState.Unknown]: (
              <div className="flex flex-col gap-4">
                {messages.length > 0 && (
                  <div className="flex justify-end gap-2">
                    <Button
                      startDecorator={<ThumbUp />}
                      onClick={onProblemSolved}
                      disabled={creatingTicket || generating}
                    >
                      {t("supportAssistant.problemSolved")}
                    </Button>
                    <Button
                      startDecorator={<ContactSupportIcon />}
                      onClick={createSupportTicket}
                      loading={creatingTicket}
                      disabled={creatingTicket || generating}
                    >
                      {t("supportAssistant.createTicket")}
                    </Button>
                  </div>
                )}
                <div
                  className={cn(
                    "flex flex-col gap-4",
                    hasStartedChat && "flex-row items-center"
                  )}
                >
                  <Textarea
                    placeholder={
                      messages.length > 0
                        ? t("supportAssistant.moreDetails")
                        : t("supportAssistant.describeProblem")
                    }
                    className="w-full"
                    disabled={generating}
                    minRows={messages.length == 0 ? 6 : undefined}
                    maxRows={18}
                    value={problemDescription}
                    onChange={(e) => {
                      setEdited(true);
                      setProblemDescription(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      if (!problemDescription) return;
                      if (creatingTicket) return;
                      if (e.key === "Enter" && !(e.shiftKey || e.altKey)) {
                        e.preventDefault();
                        void generateSolution(problemDescription);
                      }
                    }}
                  />
                  <div className="flex justify-end gap-2">
                    {generating && (
                      <IconButton
                        title={t("cancel")}
                        variant="solid"
                        color="primary"
                        onClick={() => {
                          cancelCurrent.current();
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                    )}
                    {!generating && messages.length === 0 && (
                      <Button
                        variant="solid"
                        endDecorator={<SendSharpIcon />}
                        onClick={() => {
                          setEdited(false);
                          return generateSolution(problemDescription);
                        }}
                        disabled={
                          creatingTicket || (!problemDescription && !edited)
                        }
                      >
                        {t("supportAssistant.send")}
                      </Button>
                    )}
                    {!generating && messages.length > 0 && (
                      <IconButton
                        variant="solid"
                        color="primary"
                        disabled={
                          creatingTicket || (!problemDescription && !edited)
                        }
                        title={t("supportAssistant.send")}
                        onClick={() => {
                          setEdited(false);
                          return generateSolution(problemDescription);
                        }}
                      >
                        <SendSharpIcon />
                      </IconButton>
                    )}
                  </div>
                </div>
              </div>
            ),
            [ProblemSolutionState.Solved]: (
              <div className="flex items-center gap-4">
                <Typography>
                  {t("supportAssistant.solvedSuccessfully")}
                </Typography>
                <div className="grow"></div>
                <ConfettiExplosion />
                <Button onClick={reset} startDecorator={<RestartAlt />}>
                  {t("supportAssistant.newProblem")}
                </Button>
              </div>
            ),
          }[problemSolutionState]
        }
      </div>
    </Sheet>
  );
}
