import {
  AspectRatio,
  Button,
  Card,
  CardCover,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Modal,
  ModalDialog,
  Typography,
} from "@mui/joy";
import { useTranslation } from "react-i18next";
import { trpc } from "../../lib/api/trpc/trpc";
import { Delete, Download } from "@mui/icons-material";
import { CarouselControllers } from "./CarouselControllers";
import { useState } from "react";
import { type ImageGeneration } from "../../../../backend/src/api/tools/images/imagesTypes";
import { type ImageModal } from "../../pages/[organizationId]/tools/imageFactory";
import { downloadImage } from "./utils";

export function ImagesHistory({
  imagesHistory,
  setModal,
}: {
  imagesHistory: ImageGeneration[] | undefined;
  setModal: (modal: ImageModal) => void;
}) {
  const { t } = useTranslation();

  const { mutateAsync: deleteGeneration } =
    trpc.tools.images.deleteGenerationFromHistory.useMutation();
  const utils = trpc.useUtils();

  const [deleteId, setDeleteId] = useState<string | null>(null);

  if (!imagesHistory || imagesHistory.length === 0) {
    return null;
  }

  return (
    <>
      <Modal open={!!deleteId}>
        <ModalDialog sx={{ px: 6, py: 5 }}>
          <DialogTitle>{t("images.history.delete")}</DialogTitle>
          <DialogContent>{t("images.history.delete_sub")}</DialogContent>
          <DialogActions>
            <Button
              fullWidth
              onClick={() => {
                setDeleteId(null);
              }}
              color="neutral"
            >
              {t("cancel")}
            </Button>
            <Button
              fullWidth
              onClick={() => {
                void deleteGeneration({ id: deleteId! }).then(() => {
                  void utils.tools.images.getImageGenerationHistory.invalidate();
                });
                setDeleteId(null);
              }}
              color="danger"
            >
              {t("delete")}
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
      <div>
        <Typography level="h3" pb={2}>
          {t("images.history.title")}
        </Typography>
        <div className="flex flex-wrap gap-8">
          {imagesHistory?.map((generation) => (
            <ImageGenerationHistoryCard
              setModal={setModal}
              key={generation.id}
              generation={generation}
              setDeleteId={setDeleteId}
            />
          ))}
        </div>
      </div>
    </>
  );
}

function ImageGenerationHistoryCard({
  generation,
  setDeleteId,
  setModal,
}: {
  generation: ImageGeneration;
  setDeleteId: (id: string) => void;
  setModal: (modal: ImageModal) => void;
}) {
  const { t } = useTranslation();
  const [imageIndex, setImageIndex] = useState(0);

  return (
    <Card
      variant="plain"
      className="group p-5"
      sx={{ width: 350, bgcolor: "initial", p: 0 }}
    >
      <div
        className="relative cursor-pointer "
        onClick={() => {
          setModal({
            content: "imagePreview",
            urls: generation.urls,
            index: imageIndex,
            prompt: generation.prompt,
            style: generation.style,
          });
        }}
      >
        <AspectRatio ratio="1">
          <figure>
            <img src={generation.urls[imageIndex]} loading="lazy" />
          </figure>
        </AspectRatio>
        <CardCover
          className="gradient-cover"
          sx={{
            p: 0.5,
            background:
              "linear-gradient(0deg, rgba(2,0,36,0.7) 0%, rgba(0,0,0,0.7) 30%, rgba(0,0,0,0.0) 50%, rgba(0,0,0,0.0) 100%)",
            "&:hover": {
              background:
                "linear-gradient(0deg, rgba(2,0,36,0.8) 0%, rgba(0,0,0,0.8) 30%, rgba(0,0,0,0.2) 50%, rgba(0,0,0,0.2) 100%)",
            },
          }}
        >
          <div>
            <div className="flex flex-grow flex-col gap-1.5 self-end p-2">
              <Typography level="title-sm" sx={{ color: "white" }}>
                {t("images.styles." + generation.style)}
              </Typography>
              <Typography
                level="body-md"
                className="!line-clamp-2"
                sx={{ color: "white" }}
              >
                {generation.prompt.length > 1000
                  ? generation.prompt.slice(0, 80) + "..."
                  : generation.prompt}
              </Typography>
              <div className="text-lightgray flex w-full items-center gap-2">
                <Typography level="body-sm" sx={{ color: "lightgray" }}>
                  {new Date(generation.createdAt).toLocaleDateString()}
                </Typography>
                <Typography
                  level="body-sm"
                  sx={{
                    color: "lightgray",
                  }}
                >
                  {t("images.model." + generation.model)}
                </Typography>
              </div>
            </div>
            {generation.urls.length > 1 && (
              <CarouselControllers
                imageIndex={imageIndex}
                setImageIndex={setImageIndex}
                imageUrls={generation.urls}
                className="invisible group-hover:visible"
              />
            )}
          </div>
          <div className="invisible absolute top-0 flex w-full items-center justify-between p-2 px-3 group-hover:visible">
            <div>
              {generation.urls.length > 1 && (
                <Typography level="title-md" textColor="white">
                  {imageIndex + 1}/{generation.urls.length}
                </Typography>
              )}
            </div>
            <div className="flex gap-2">
              <IconButton
                size="sm"
                variant="solid"
                color="neutral"
                sx={{ ml: "auto", bgcolor: "rgba(0 0 0 / 0.2)" }}
                onClick={(e) => {
                  e.stopPropagation();
                  setDeleteId(generation.id);
                }}
              >
                <Delete />
              </IconButton>
              <IconButton
                component="a"
                size="sm"
                variant="solid"
                color="neutral"
                sx={{ bgcolor: "rgba(0 0 0 / 0.2)" }}
                onClick={(e) => {
                  e.stopPropagation();
                  downloadImage(
                    generation.urls[imageIndex],
                    t("erros.couldNotDownloadImage")
                  );
                }}
              >
                <Download />
              </IconButton>
            </div>
          </div>
        </CardCover>
      </div>
    </Card>
  );
}
