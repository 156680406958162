import { AutoAwesome } from "@mui/icons-material";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemDecorator,
  ListItemContent,
  Divider,
} from "@mui/joy";
import { t } from "i18next";
import { BrandedLogo } from "../../branding/BrandedLogo";
import ChatTree from "../ChatTree";
import { OrganizationTree } from "../OrganizationTree";
import ToolsList from "../ToolsList";
import { Link as RouterLink, useNavigate, useParams } from "../../../router";
import { type BottomButton } from "../Sidebar";
import { comparePath } from "../../../lib/routeUtils";
import { UserMenu } from "../../auth/UserMenu";
import { useOrganization } from "../../../lib/api/organization";
import { useLocation } from "react-router-dom";
import { PhaseContainer } from "../PhaseContainer";

export function ExpandedSidebar({
  makeTourStep,
  bottomButtons,
}: {
  makeTourStep: () => void;
  bottomButtons: BottomButton[];
}) {
  const params = useParams("/:organizationId");

  const organizationId = params.organizationId;

  const navigate = useNavigate();

  const organization = useOrganization();
  const path = useLocation().pathname;

  return (
    <div className="flex h-full flex-col gap-4 px-3">
      <div
        className="flex w-full cursor-pointer flex-col items-center py-5"
        onClick={() => {
          navigate(`/:organizationId`, {
            params,
          });
        }}
      >
        <BrandedLogo className="h-16 px-10" />
      </div>
      <List
        sx={{
          "--ListItem-radius": "8px",
          "--List-gap": "4px",
          "--List-nestedInsetStart": "40px",
          flexGrow: 0,
        }}
      >
        <RouterLink
          to="/:organizationId"
          params={{
            organizationId,
          }}
          onClick={makeTourStep}
        >
          <ListItem id="newChatButton">
            <ListItemButton variant="solid" color="primary">
              <ListItemDecorator>
                <AutoAwesome fontSize="small" />
              </ListItemDecorator>
              <ListItemContent className="flex flex-row items-center gap-2">
                {t("newChat")}
              </ListItemContent>
            </ListItemButton>
          </ListItem>
        </RouterLink>
      </List>
      <Box
        sx={{
          minHeight: 0,
          overflow: "hidden auto",
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
        }}
      >
        <div className="flex flex-col justify-start">
          <ChatTree />
          <ToolsList />
          <OrganizationTree />
        </div>
      </Box>
      <div className="flex flex-col gap-4">
        <List
          sx={{
            mt: "auto",
            pt: 0,
            flexGrow: 0,
            "--ListItem-radius": "8px",
            "--List-gap": "8px",
          }}
        >
          {bottomButtons.map((button) => {
            return (
              button.doesRender && (
                <ListItem key={button.text}>
                  <ListItemButton
                    data-testid={button.testId}
                    selected={button.url && comparePath(path, button.url)}
                    onClick={
                      button.onClick ??
                      (() => {
                        button.url &&
                          navigate(button.url, {
                            params: {
                              organizationId,
                              workshopId: organization?.defaultWorkshopId ?? "",
                            },
                          });
                      })
                    }
                  >
                    <ListItemDecorator>{button.icon}</ListItemDecorator>
                    <ListItemContent>{button.text}</ListItemContent>
                  </ListItemButton>
                </ListItem>
              )
            );
          })}
        </List>
        <Divider />
        {organization && organization?.phase !== "NORMAL" && (
          <PhaseContainer
            phase={organization.phase}
            start={organization.phaseStartDate ?? ""}
            end={organization.phaseEndDate ?? ""}
          />
        )}
        <div className="flex items-center gap-3">
          <UserMenu languageSelector />
          <div className="text-sm font-bold">
            @ {organization && organization.name}
          </div>
        </div>
        <Divider />
        <div className="-my-2 flex flex-col items-center gap-1 pb-2">
          <div className="text-xs">
            powered by{" "}
            <a
              href="https://meingpt.com/"
              className="text-blue-500 underline transition-all hover:text-blue-700"
            >
              meinGPT
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
