import { Button, Checkbox, Stack, Textarea, Typography } from "@mui/joy";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { trpc } from "../../lib/api/trpc/trpc";
import { useTranslation } from "react-i18next";
import { TextSnippet } from "@mui/icons-material";
import { DEFAULT_GUIDELINES } from "../../lib/constants/DEFAULT_GUIDELINES";
import { ProductConfig } from "../../../../backend/src/api/organization/productConfig/productConfigTypes";
import { ZodBoolean } from "zod";
import type { LlmName } from "../../../../backend/src/ai/llmMeta";
import { LlmNames } from "../../../../backend/src/ai/llmMeta";
import { SettingsPage } from "./SettingsPage";

/**
 * Dynamically infer the available product config flags from the ProductConfig shape
 */
const productConfigFlagOptions = Object.entries(ProductConfig.shape)
  .filter(([, value]) => value instanceof ZodBoolean)
  .map(([key]) => key);

export function ProductConfigEditor() {
  const { t } = useTranslation();
  const utils = trpc.useUtils();
  const { data: productConfig } = trpc.productConfig.get.useQuery();
  const mutateProductConfig =
    trpc.productConfig.patch.useMutation().mutateAsync;

  const enabledModels = productConfig?.enabledModels ?? [];
  const toggleModel = (model: string) => {
    void mutateProductConfig({
      enabledModels: enabledModels?.includes(model)
        ? enabledModels.filter((m) => m !== model)
        : [...enabledModels, model],
    }).then(() => {
      void utils.invalidate();
    });
  };

  const toggleService = (service: string) => {
    void mutateProductConfig({
      [service]: !productConfig?.[service],
    }).then(() => {
      void utils.invalidate();
    });
  };

  const [guidelinesInput, setGuidelinesInput] = useState("");
  useEffect(() => {
    if (productConfig) {
      setGuidelinesInput(productConfig?.guidelines ?? "");
    }
  }, [productConfig]);
  const saveGuidelines = () => {
    void mutateProductConfig({
      guidelines: guidelinesInput,
    }).then(() => {
      toast.success(t("guidelinesSaved"));
    });
  };

  const noProductConfig =
    productConfig === null ? t("errors.noProductConfig") : "Loading...";
  return (
    <SettingsPage title={t("productConfigs")}>
      {!productConfig ? (
        <Typography>{noProductConfig}</Typography>
      ) : (
        <Stack gap={6}>
          <Stack gap={2}>
            <Typography level="h4">LLMs:</Typography>
            {Array.from(
              new Set([...LlmNames, ...enabledModels] as LlmName[]) // to make sure also old deprecated models are shown, so they can be disabled
            ).map((model) => (
              <Checkbox
                checked={enabledModels?.includes(model) ?? false}
                onChange={() => {
                  toggleModel(model);
                }}
                label={model}
                key={model}
              />
            ))}
          </Stack>
          <Stack gap={2}>
            <Typography level="h4">Flags:</Typography>
            {productConfigFlagOptions.map((service) => (
              <Checkbox
                label={beautifyCamelCase(service)}
                checked={productConfig[service]}
                key={service}
                data-testid={`pcCheckbox-${service}`}
                onChange={() => {
                  toggleService(service);
                }}
              />
            ))}
          </Stack>
          <Stack gap={2}>
            <Stack direction="row" justifyContent="space-between">
              <Typography level="h4">GPT-Guidelines:</Typography>
              <Button
                endDecorator={<TextSnippet />}
                onClick={() => {
                  setGuidelinesInput(DEFAULT_GUIDELINES);
                }}
              >
                {t("defaultTemplate")}
              </Button>
            </Stack>
            <Textarea
              placeholder="Guidelines..."
              sx={{
                height: "800px",
                "&>textarea": { overflowY: "scroll !important" },
                padding: "30px",
              }}
              value={guidelinesInput}
              onChange={(e) => {
                setGuidelinesInput(e.target.value);
              }}
            />
            <Button
              onClick={saveGuidelines}
              disabled={productConfig.guidelines === guidelinesInput}
              sx={{ mt: 2 }}
            >
              {t("saveGuidelines")}
            </Button>
          </Stack>
        </Stack>
      )}
    </SettingsPage>
  );
}

function beautifyCamelCase(input: string) {
  return input
    .replace(/([A-Z])/g, " $1")
    .replace(/^./, (str) => str.toUpperCase());
}
