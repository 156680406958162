import { create } from "zustand";
import { persist } from "zustand/middleware";
import { type SelectedLanguages } from "../../pages/[organizationId]/tools/translateContent";

interface TranslateStore {
  selectedLanguages: SelectedLanguages;
  setSelectedLanguages: (selectedLanguages: SelectedLanguages) => void;
}

export const useTranslateStore = create(
  persist<TranslateStore>(
    (set) => ({
      selectedLanguages: {
        sourceLanguage: "detect",
        targetLanguage: "en-GB",
      },
      setSelectedLanguages: (selectedLanguages) => set({ selectedLanguages }),
    }),
    {
      name: "content-translate-store",
    }
  )
);
