import { Add, Delete } from "@mui/icons-material";
import {
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  List,
  ListItem,
  Typography,
} from "@mui/joy";
import { Stack } from "@mui/system";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { isValidURL } from "../../../../lib/util";

interface Website {
  url: string;
}

export default function WebsiteSourceEditor() {
  const [input, setInput] = useState<string>("");
  const [websites, setWebsites] = useState<Website[]>([]);
  const { t } = useTranslation();

  const validInput = useMemo(() => isValidURL(input.trim()), [input]);

  const handleAdd = () => {
    setWebsites((prev) => [...prev, { url: input }]);
    setInput("");
  };

  return (
    <List>
      <Typography level="body-md">
        {t("knowledgeBase.websitesToScrape")}
      </Typography>
      {websites.map(({ url }, index) => (
        <ListItem key={index}>
          <Stack direction="row" width="100%" spacing={1} alignItems="flex-end">
            <Input
              fullWidth
              placeholder="http://google.com"
              value={url}
              onChange={(e) => console.log(e.target.value)}
            />

            <IconButton
              sx={{
                border: 1,
                borderColor: "lightgrey",
                mb: 4,
              }}
              onClick={() =>
                setWebsites((prev) => prev.filter((_, i) => i !== index))
              }
            >
              <Delete />
            </IconButton>
          </Stack>
        </ListItem>
      ))}
      <ListItem>
        <Stack direction="row" width="100%" spacing={1}>
          <FormControl sx={{ flex: 1 }}>
            <Stack direction="row" spacing={1}>
              <Input
                fullWidth
                sx={{ height: 30 }}
                placeholder={t("knowledgeBase.addAnother")}
                value={input}
                onChange={(e) => setInput(e.target.value)}
              />
              <IconButton
                disabled={!validInput}
                sx={{
                  border: 1,
                  borderColor: "lightgrey",
                  mb: 4,
                }}
                onClick={handleAdd}
              >
                <Add />
              </IconButton>
            </Stack>

            {!validInput && input.length > 0 && (
              <FormHelperText>{t("Please enter a valid URL.")}</FormHelperText>
            )}
          </FormControl>
        </Stack>
      </ListItem>
    </List>
  );
}
