import { DoNotDisturb } from "@mui/icons-material";

import advertising from "./advertising.png";
import digital_illustration from "./illustration.png";
import interior from "./interior.png";
import logo from "./logo.png";
import memphis from "./memphis.png";
import minimalistic from "./minimalistic.png";
import productMockup from "./productmockup.png";
import productPresentation from "./productpresentation.png";
import realistic_image from "./realistic.png";
import socialMedia from "./socialmedia.png";
import stock from "./stock.png";
import ui from "./ui.png";
import vector_illustration from "./vector.png";
import wallpaper from "./wallpaper.png";

export const imageStyleExamples = {
  none: (
    <div
      className="flex h-full w-full items-center justify-center"
      style={{
        fontSize: "100px",
      }}
    >
      <DoNotDisturb fontSize="inherit" />
    </div>
  ),
  advertising,
  digital_illustration,
  interior,
  logo,
  memphis,
  minimalistic,
  productMockup,
  productPresentation,
  realistic_image,
  socialMedia,
  stock,
  ui,
  vector_illustration,
  wallpaper,
};
